




















































import { RequestJobCostingMeatroomProduceUpdate } from "@/models/interface-v2/job-costing-meatroom.interface";
import { Decimal } from "decimal.js-light";
import Vue from "vue";

export default Vue.extend({
  name: "ModalConfirmJC",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    products: {
      type: Array as () => RequestJobCostingMeatroomProduceUpdate[],
      default: undefined,
    },
  },
  methods: {
    close(): void {
      this.$emit("input", false);
    },
    save(): void {
      this.$emit("on-save");
    },
    countTotalWaste({ wasteQtyTangible, wasteQtyIntangible }: { wasteQtyTangible: number, wasteQtyIntangible: number }): number {
      return new Decimal(wasteQtyTangible ?? 0).plus(wasteQtyIntangible ?? 0).toNumber();
    }
  },
});
