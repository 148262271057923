import {
  IImportCostCreate,
  IImportCostLineCreate,
} from "@/models/interface-v2/import-cost.interface";
import { ResponseReceivingItem } from "@/models/interface-v2/receiving-item.interface";
import localStorageService from "@/services/localStorage.service";
import { initForm } from "./resource/import-cost.resource";
import { GR_LOCAL_STORAGE, GR_STATUS } from "@enum/good-receipt.enum";
import { Decimal } from "decimal.js-light";
import { DECIMAL_PLACES_CURRENCY } from "@/models/constant/global.constant";
import { APP_DECIMAL_PLACES } from "@/models/enums/global.enum";

export interface State {
  goodReceiptDetail: ResponseReceivingItem | null;
  form: IImportCostCreate;
  goodReceiptPurchaseForm: any;
  toggleSection: {
    detail: boolean;
    productDetail: boolean;
    customerClearance: boolean;
    ppjkServiceDetail: boolean;
    shouldPay: boolean;
    warehouseRentDetail: boolean;
    unloadingCostDetail: boolean;
    documentFeeDetail: boolean;
    quarantineFeeDetail: boolean;
  };
  goodReceiptStatus: GR_STATUS | null;
  ratePph: number;
  ratePpn: number;
}

const state: State = {
  goodReceiptDetail: null,
  form: initForm(),
  goodReceiptPurchaseForm: null,
  toggleSection: {
    detail: true,
    productDetail: false,
    customerClearance: false,
    ppjkServiceDetail: false,
    shouldPay: false,
    warehouseRentDetail: false,
    unloadingCostDetail: false,
    documentFeeDetail: false,
    quarantineFeeDetail: false,
  },
  goodReceiptStatus: null,
  ratePph: 0,
  ratePpn: 0,
};

const getters = {
  ["GET_STATUS_GOOD_RECEIPT"]: (st: State): string => {
    return st.goodReceiptStatus || "";
  },
};

const mutations = {
  ["SET_GOOD_RECEIPT_DETAIL"]: (
    st: State,
    payload: ResponseReceivingItem
  ): void => {
    st.goodReceiptDetail = payload;
  },
  ["SET_FORM"]: (st: State, payload: IImportCostCreate): void => {
    st.form = payload;
  },
  ["SET_GOOD_RECEIPT_PURCHASE_FORM"]: (st: State, payload: any): void => {
    st.goodReceiptPurchaseForm = payload;
  },
  ["TOGGLE_SECTION_DETAIL"]: (st: State, key: string): void => {
    st.toggleSection[key] = !st.toggleSection[key];
  },
  ["SET_GOOD_RECEIPT_STATUS"]: (st: State, value: GR_STATUS): void => {
    st.goodReceiptStatus = value;
  },
  ["SET_RATE_PPH"]: (st: State, value: number): void => {
    st.ratePph = value;
  },
  ["SET_RATE_PPN"]: (st: State, value: number): void => {
    st.ratePpn = value;
  },
};
const actions = {
  ["ACT_RESET_STATE"]: (context): void => {
    context.commit("SET_GOOD_RECEIPT_DETAIL", null);
    context.commit("SET_GOOD_RECEIPT_PURCHASE_FORM", null);
    context.commit("SET_FORM", initForm());
    sessionStorage.removeItem(GR_LOCAL_STORAGE.GR_PURCHASE_FORM);
  },
  /**
   * init form value
   * @param context store context
   * @param payload given data
   */
  ["ACT_FILL_FORM"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const $form: IImportCostCreate = { ...context.state.form };
    const detail: ResponseReceivingItem = {
      ...context.state.goodReceiptDetail,
    };
    const cache = sessionStorage.getItem(GR_LOCAL_STORAGE.GR_PURCHASE_FORM);
    if (!cache) return;
    const $formgr = JSON.parse(cache);
    if ($formgr.grChecklistNumber !== detail?.checklistNumber) return;
    $form.supplierBillToAddress = "";
    $form.checklistNumber = detail?.checklistNumber;
    $form.priceCurrency = $formgr.currency;
    $form.priceCurrencyId = $formgr.currencyId;
    $form.currencyRate = $formgr.currencyRates;
    $form.receivingItemId = $formgr.grChecklistId;
    $form.importCostLines = $formgr.productLines.map((x, i) => ({
      no: i + 1,
      batchId: x.batchNumberId,
      cogs: 0,
      gijConversion: 0,
      importDuty: 0,
      localTransportation: 0,
      portion: 0,
      price: x.unitPrice,
      productId: x.productId,
      qty: x.qtyReceived,
      receivingItemLineId: x.key,
      total: 0,
      totalIDR: 0,
      // AB
      totalPrice: new Decimal(x.total || 0)
        .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
        .toNumber(),
      transport: 0,
      uomId: x.productUomId,
      productCode: x.productCode,
      productName: x.productName,
      uom: x.productUom,
    }));

    context.commit("SET_FORM", $form);
    context.dispatch("RECALCULATE_ALL");
  },
  ["CALC_PRICING"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const {
      totalAmountPrice,
      totalImportDuty,
      totalLocalTransportation,
      transportFee,
      currencyRate,
    } = context.state.form as IImportCostCreate;
    context.state.form.importCostLines.forEach(
      (item: IImportCostLineCreate) => {
        // calc portion
        const portion = new Decimal(item.totalPrice || 0)
          .dividedBy(totalAmountPrice || 1)
          .times(100)
          .toDecimalPlaces(14, Decimal.ROUND_DOWN);
        item.portion = portion.toNumber();

        // calc transport
        const portionPercentage = new Decimal(item.portion || 0).dividedBy(100);
        item.transport = new Decimal(transportFee)
          .times(portionPercentage)
          .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();

        // calc total
        item.total = new Decimal(item.totalPrice)
          .plus(item.transport)
          .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();

        // calc GIJ Conversion
        item.gijConversion = new Decimal(item.total)
          .times(currencyRate || 0)
          .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();

        // calc local transportation
        item.localTransportation = new Decimal(item.portion)
          .dividedBy(100)
          .times(totalLocalTransportation || 0)
          .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();

        // calc import duty
        item.importDuty = new Decimal(item.portion)
          .dividedBy(100)
          .times(totalImportDuty || 0)
          .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();

        // total IDR
        item.totalIDR = new Decimal(item.localTransportation)
          .plus(item.importDuty)
          .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();

        // calc COGS
        item.cogs = new Decimal(item.gijConversion)
          .plus(item.totalIDR)
          .dividedBy(item.qty)
          .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();
      }
    );

    context.dispatch("CALC_TOTAL_GIJ_CONVERSION");
  },
  ["CALC_TOTAL_AMOUNT_PRICE"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const total: number = context.state.form.importCostLines.reduce(
      (a: number, b: IImportCostLineCreate) => {
        return new Decimal(b.totalPrice || 0).plus(a).toNumber();
      },
      0
    );
    context.state.form.totalAmountPrice = new Decimal(total)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  ["CALC_TOTAL_GIJ_CONVERSION"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const total: number = context.state.form.importCostLines.reduce(
      (a: number, b: IImportCostLineCreate) => {
        return new Decimal(b.gijConversion || 0).plus(a).toNumber();
      },
      0
    );
    context.state.form.totalConversion = new Decimal(total)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  ["CALC_TOTAL_LOCAL_TRANSPORTATION"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { totalPpjk, totalDetail, totalService } = context.state
      .form as IImportCostCreate;
    const value1 = new Decimal(totalPpjk || 0)
      .minus(totalDetail)
      .absoluteValue();
    context.state.form.totalLocalTransportation = value1
      .plus(totalService || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * formula = (gross weight kgs * price kgs) + pph23
   */
  ["CALC_TOTAL_SERVICE"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { grossWeight, pph23, price } = context.state
      .form as IImportCostCreate;
    const gross = new Decimal(grossWeight || 0).times(price || 0);
    context.state.form.totalService = new Decimal(gross)
      .minus(pph23 || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * formula = Tax Fee + Total Warehouse Rent +
   * Total unloading cost +
   * total document fee +
   * PPJK & EDI +
   * stamp duty
   */
  ["CALC_TOTAL_PPJK"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const {
      taxFee,
      totalWarehouseRent,
      totalUnloadingCost,
      totalDocumentFee,
      ppjkEdi,
      stampDuty,
      totalQuarantineFee,
    } = context.state.form as IImportCostCreate;
    context.state.form.totalPpjk = new Decimal(0)
      .plus(taxFee || 0)
      .plus(totalQuarantineFee || 0)
      .plus(totalWarehouseRent || 0)
      .plus(totalUnloadingCost || 0)
      .plus(totalDocumentFee || 0)
      .plus(ppjkEdi || 0)
      .plus(stampDuty || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * formula = Bea Masuk + PPh
   */
  ["CALC_TAX_FEE"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { totalDetail } = context.state.form as IImportCostCreate;
    context.state.form.taxFee = new Decimal(totalDetail)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  ["CALC_TOTAL_DETAIL"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { beaMasuk, pph } = context.state.form as IImportCostCreate;
    context.state.form.totalDetail = new Decimal(beaMasuk || 0)
      .plus(pph)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  ["CALC_PPH"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { nilaiPabean, beaMasuk } = context.state.form as IImportCostCreate;
    const sumGH = new Decimal(nilaiPabean || 0).plus(beaMasuk || 0);
    const rate = new Decimal(context.state.ratePph).dividedBy(100);
    const value = new Decimal(sumGH).times(rate);
    context.state.form.pph = value
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * calc Nilai Pabean.
   * formula = CIF * Currency Rates
   */
  ["CALC_NILAI_PABEAN"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { cif, currencyRate } = context.state.form as IImportCostCreate;
    context.state.form.nilaiPabean = new Decimal(cif || 0)
      .times(currencyRate || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * calc CIF.
   * formula = CNF + INS
   */
  ["CALC_CIF"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { cnf, ins } = context.state.form as IImportCostCreate;
    context.state.form.cif = new Decimal(cnf || 0)
      .plus(ins || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * calc INS.
   * formula = CNF * (0.5 / 100).
   */
  ["CALC_INS"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { cnf } = context.state.form as IImportCostCreate;
    const rate = new Decimal(0.5).dividedBy(100);
    const value = new Decimal(cnf || 0).times(rate);
    context.state.form.ins = value
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * calc CNF.
   * formula = FOB Total + Freight.
   */
  ["CALC_CNF"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { fobTotal, freight } = context.state.form as IImportCostCreate;
    context.state.form.cnf = new Decimal(fobTotal || 0)
      .plus(freight || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * formula = total price (include discount + tax)
   */
  ["CALC_FOB_TOTAL"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { totalAmountPrice } = context.state.form as IImportCostCreate;
    context.state.form.fobTotal = new Decimal(totalAmountPrice || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  ["CALC_TOTAL_WAREHOUSE_RENT"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const {
      document,
      rushHoldingTotal,
      storageChargeTotal,
      airContributionTotal,
      kadeChargeTotal,
      pjkp2uTotal,
      taxWarehouseTotal,
      otherWarehouse,
      stampDutyWarehouse,
    } = context.state.form as IImportCostCreate;
    context.state.form.totalWarehouseRent = new Decimal(0)
      .plus(document || 0)
      .plus(rushHoldingTotal || 0)
      .plus(storageChargeTotal || 0)
      .plus(airContributionTotal || 0)
      .plus(kadeChargeTotal || 0)
      .plus(pjkp2uTotal || 0)
      .plus(taxWarehouseTotal || 0)
      .plus(otherWarehouse || 0)
      .plus(stampDutyWarehouse || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * formula = handling fee +
   * tax +
   * stamp duty +
   * others
   */
  ["CALC_TOTAL_UNLOADING_COST"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const {
      handlingFeeTotal,
      taxUnloadingTotal,
      stampDutyUnloading,
      otherUnloading,
    } = context.state.form as IImportCostCreate;
    context.state.form.totalUnloadingCost = new Decimal(0)
      .plus(handlingFeeTotal || 0)
      .plus(taxUnloadingTotal || 0)
      .plus(stampDutyUnloading || 0)
      .plus(otherUnloading || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * formula = moving cost +
   * document fee +
   * tax document fee +
   * stamp duty +
   * others
   */
  ["CALC_TOTAL_DOCUMENT_FEE"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const {
      movingCostDocumentFee,
      documentFee,
      taxDocumentFeeTotal,
      stampDutyDocumentFee,
      otherDocumentFee,
    } = context.state.form as IImportCostCreate;
    context.state.form.totalDocumentFee = new Decimal(0)
      .plus(movingCostDocumentFee || 0)
      .plus(documentFee || 0)
      .plus(taxDocumentFeeTotal || 0)
      .plus(stampDutyDocumentFee || 0)
      .plus(otherDocumentFee || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * calc PPN.
   * rate PPn from preference.
   * formula = ( Nilai Pabean + Bea Masuk ) * rate PPN
   */
  ["CALC_PPN"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { nilaiPabean, beaMasuk } = context.state.form as IImportCostCreate;
    // Nilai Pabean (G) + Bea masuk (H)
    const sumGH = new Decimal(nilaiPabean || 0).plus(beaMasuk || 0);
    const rate = new Decimal(context.state.ratePpn || 0).dividedBy(100);
    context.state.form.ppn = new Decimal(sumGH)
      .times(rate)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  ["CALC_WEIGHT_NETT"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { totalQtyReceived } = context.state.form as IImportCostCreate;
    context.state.form.weightNett = new Decimal(totalQtyReceived || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  ["CALC_TOTAL_QTY_RECEIVED"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const r = state.form.importCostLines.reduce(
      (a, b) => new Decimal(b.qty || 0).plus(a).toNumber(),
      0
    );
    context.state.form.totalQtyReceived = new Decimal(r || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * should be 100%
   * formula = sum of portion
   */
  ["CALC_TOTAL_PORTION"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const r = state.form.importCostLines.reduce(
      (a, b) => new Decimal(b.portion || 0).plus(a).toNumber(),
      0
    );
    context.state.form.totalPortion = new Decimal(r || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * formula = sum of total column
   */
  ["CALC_TOTAL_FEE"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const r = state.form.importCostLines.reduce(
      (a, b) => new Decimal(b.total || 0).plus(a).toNumber(),
      0
    );
    context.state.form.totalFee = new Decimal(r || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  ["CALC_TOTAL_IMPORT_DUTY"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { beaMasuk } = context.state.form as IImportCostCreate;
    context.state.form.totalImportDuty = new Decimal(beaMasuk || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * formula = total local transportion + total import duty
   */
  ["CALC_TOTAL_FEE_AFTER_IDR"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { totalLocalTransportation, totalImportDuty } = context.state
      .form as IImportCostCreate;
    context.state.form.totalFeeAfterIDR = new Decimal(
      totalLocalTransportation || 0
    )
      .plus(totalImportDuty || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  ["CALC_TOTAL_PRICE"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { fobTotal } = context.state.form as IImportCostCreate;
    context.state.form.totalPrice = new Decimal(fobTotal || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  ["CALC_TOTAL_PAYMENT"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { totalPpjk, totalService } = context.state.form as IImportCostCreate;
    context.state.form.totalPayment = new Decimal(totalPpjk || 0)
      .plus(totalService || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  ["CALC_RUSH_HOLDING_TOTAL"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { grossWeight, rushHoldingValue } = context.state
      .form as IImportCostCreate;
    context.state.form.rushHoldingTotal = new Decimal(grossWeight || 0)
      .times(rushHoldingValue || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  ["CALC_STORAGE_CHARGE_TOTAL"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { storageChargeDay, storageChargeValue, storageChargeValue1 } =
      context.state.form as IImportCostCreate;
    context.state.form.storageChargeTotal = new Decimal(storageChargeDay || 0)
      .times(storageChargeValue || 0)
      .times(storageChargeValue1 || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * formula = Gross Weight * air contribution value
   */
  ["CALC_AIR_CONTRIBUTION_TOTAL"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { grossWeight, airContributionValue } = context.state
      .form as IImportCostCreate;
    context.state.form.airContributionTotal = new Decimal(grossWeight || 0)
      .times(airContributionValue || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * formula = Gross Weight * kade charge value
   */
  ["CALC_KADE_CHARGE_TOTAL"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { grossWeight, kadeChargeValue } = context.state
      .form as IImportCostCreate;
    context.state.form.kadeChargeTotal = new Decimal(grossWeight || 0)
      .times(kadeChargeValue || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * formula = gross weight * pjkp2u value
   */
  ["CALC_PJKP2U_TOTAL"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { grossWeight, pjkp2uValue } = context.state
      .form as IImportCostCreate;
    context.state.form.pjkp2uTotal = new Decimal(grossWeight || 0)
      .times(pjkp2uValue || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * formula = Rush handling + storage charge +
   * air contribution +
   * KADE charge +
   * PJKP2U
   */
  ["CALC_TAX_WAREHOUSE_VALUE"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const {
      rushHoldingTotal,
      storageChargeTotal,
      airContributionTotal,
      kadeChargeTotal,
      pjkp2uTotal,
      document,
    } = context.state.form as IImportCostCreate;
    context.state.form.taxWarehouseValue = new Decimal(0)
      .plus(rushHoldingTotal || 0)
      .plus(storageChargeTotal || 0)
      .plus(airContributionTotal || 0)
      .plus(kadeChargeTotal || 0)
      .plus(pjkp2uTotal || 0)
      .plus(document || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * formula = tax warehouse value * percentage
   */
  ["CALC_TAX_WAREHOUSE_TOTAL"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { taxWarehousePercent, taxWarehouseValue } = context.state
      .form as IImportCostCreate;
    const rate = new Decimal(taxWarehousePercent || 0).dividedBy(100);
    const value = new Decimal(taxWarehouseValue || 0).times(rate);
    context.state.form.taxWarehouseTotal = new Decimal(value || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * formula = gross weight * handling fee value
   */
  ["CALC_HANDLING_FEE_TOTAL"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { grossWeight, handlingFeeValue } = context.state
      .form as IImportCostCreate;
    const value = new Decimal(grossWeight || 0).times(handlingFeeValue || 0);
    context.state.form.handlingFeeTotal = new Decimal(value || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * formula = handling fee * percentage
   */
  ["CALC_TAX_UNLOADING_TOTAL"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { taxUnloadingPercent, handlingFeeTotal } = context.state
      .form as IImportCostCreate;
    const rate = new Decimal(taxUnloadingPercent || 0).dividedBy(100);
    const value = new Decimal(handlingFeeTotal || 0).times(rate);
    context.state.form.taxUnloadingTotal = new Decimal(value || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * formula = moving cost + document fee
   */
  ["CALC_TAX_DOCUMENT_FEE_VALUE"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { movingCostDocumentFee, documentFee } = context.state
      .form as IImportCostCreate;
    context.state.form.taxDocumentFeeValue = new Decimal(
      movingCostDocumentFee || 0
    )
      .plus(documentFee || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * formula = tax document fee value * percentage
   */
  ["CALC_TAX_DOCUMENT_FEE_TOTAL"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { taxDocumentFeePercent, taxDocumentFeeValue } = context.state
      .form as IImportCostCreate;
    const rate = new Decimal(taxDocumentFeePercent || 0).dividedBy(100);
    const value = new Decimal(taxDocumentFeeValue || 0).times(rate);
    context.state.form.taxDocumentFeeTotal = new Decimal(value || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * formula = weight nett * lab fee value
   */
  ["CALC_LAB_FEE_TOTAL"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { weightNett, labFeeValue } = context.state.form as IImportCostCreate;
    context.state.form.labFeeTotal = new Decimal(weightNett || 0)
      .times(labFeeValue || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  /**
   * formula = lab fee +
   * certificate fee +
   * travel expense +
   * others
   */
  ["CALC_TOTAL_QUARANTINE_FEE"]: (context): void => {
    const dp = +(
      localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY
    );
    const { labFeeTotal, certificateFee, travelExpanse, otherQuarantineFee } =
      context.state.form as IImportCostCreate;
    context.state.form.totalQuarantineFee = new Decimal(0)
      .plus(labFeeTotal || 0)
      .plus(certificateFee || 0)
      .plus(travelExpanse || 0)
      .plus(otherQuarantineFee || 0)
      .toDecimalPlaces(dp, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  },
  ["RECALCULATE_ALL"]: ({ dispatch }): void => {
    dispatch("CALC_TOTAL_AMOUNT_PRICE");
    dispatch("CALC_TOTAL_IMPORT_DUTY");

    dispatch("CALC_PRICING");
    dispatch("CALC_FOB_TOTAL");
    dispatch("CALC_CNF");
    dispatch("CALC_INS");
    dispatch("CALC_CIF");
    dispatch("CALC_NILAI_PABEAN");
    dispatch("CALC_PPN");
    dispatch("CALC_PPH");
    dispatch("CALC_TOTAL_DETAIL");

    dispatch("CALC_WEIGHT_NETT");
    dispatch("CALC_TOTAL_PRICE");

    dispatch("CALC_TAX_FEE");

    // start total warehouse components
    dispatch("CALC_RUSH_HOLDING_TOTAL");
    dispatch("CALC_STORAGE_CHARGE_TOTAL");
    dispatch("CALC_AIR_CONTRIBUTION_TOTAL");
    dispatch("CALC_KADE_CHARGE_TOTAL");
    dispatch("CALC_PJKP2U_TOTAL");
    dispatch("CALC_TAX_WAREHOUSE_VALUE");
    dispatch("CALC_TAX_WAREHOUSE_TOTAL");
    // calc total warehouse
    dispatch("CALC_TOTAL_WAREHOUSE_RENT");
    // end

    // start total unloading cost detail
    dispatch("CALC_HANDLING_FEE_TOTAL");
    dispatch("CALC_TAX_UNLOADING_TOTAL");
    // calc total unloading cost
    dispatch("CALC_TOTAL_UNLOADING_COST");
    // end

    // start total document fee details
    dispatch("CALC_TAX_DOCUMENT_FEE_VALUE");
    dispatch("CALC_TAX_DOCUMENT_FEE_TOTAL");
    // calc total document fee
    dispatch("CALC_TOTAL_DOCUMENT_FEE");
    // end

    // calc total ppjk service details
    dispatch("CALC_TOTAL_SERVICE");

    // start calc total quarantine fee
    dispatch("CALC_LAB_FEE_TOTAL");
    // calc total quarantine fee
    dispatch("CALC_TOTAL_QUARANTINE_FEE");
    // end

    dispatch("CALC_TOTAL_QTY_RECEIVED");
    dispatch("CALC_TOTAL_PORTION");
    dispatch("CALC_TOTAL_FEE");
    dispatch("CALC_TOTAL_GIJ_CONVERSION");

    dispatch("CALC_TOTAL_PPJK");
    dispatch("CALC_TOTAL_PAYMENT");
    dispatch("CALC_TOTAL_LOCAL_TRANSPORTATION");
    dispatch("CALC_PRICING");
    dispatch("CALC_TOTAL_FEE_AFTER_IDR");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
